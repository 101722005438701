







































































































@use "@material/switch";
@include switch.core-styles;

.mdc-switch {
  @include switch.toggled-on-color(rgba(0, 0, 0, 0.6));
  @include switch.toggled-off-color(rgba(0, 0, 0, 0.2));
}

$color_black: var(--color-text-primary);
$color_dark: #000010;
$color_grey: var(--color-text-secondary);
$back_grey: #F3F4F7;

h3, #text-description {
  color: var(--color-text-primary);
}

.stoploss-wrapper {
  margin-top: 100px;
  margin-left: 80px;}
p {
  margin: 0;}
hr{
  border-top: 1px rgba(46, 45, 44, 0.08) solid;
  margin: 30px 20px;
  width: 500px;}
.center-container{
  margin-top: 30px;}
.stoploss-title {
  color: $color_dark;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;}
.stoploss-text {
  color: $color_dark;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;}
.stoploss-ipo {
  margin: 30px 0;
  display: flex;
  align-items: center;}
.stoploss-result {
  margin: 0 20px;}
.text-grey {
  color: $color_grey;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;}
.text-black {
  margin-left: 60px;
  color: $color_black;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15px;}
.margin-right {
  margin-right: 12px;}
.margin-left {
  margin-left: 12px;}
.margin-bottom {
  margin-bottom: 30px;}
.input-container-size {
  max-width: 60%;}
.stoploss-input{
  width: 230px;}

@media screen and (max-width: 600px) {
  #title-text {
    margin-top: 50px;
    margin-bottom: 12px;
  }
  .stoploss-wrapper {
    margin: 0;
  }
  .main-container{
    width: 90%;
    margin: auto;
  }
  hr{
    width: 250px;
  }
}

@media screen and (min-width: 601px) {
  #title-text {
    margin-top: 0px;
    padding-top: 50px;
    margin-bottom: 12px;
  }
  .stoploss-wrapper {
    margin: 0;
  }
  .main-container{
    width: 90%;
    margin: auto;
  }
}
